<template>
  <MoleculeText title="Privacy Policy">
    <template #default>
      <p>
        Tracklimit Ltd has created this privacy statement in order to demonstrate our firm commitment to privacy. The
        following discloses our information gathering and dissemination practices for this website. We do not store
        personal or financial information in the process of payment, nor do we share such information with 3rd parties.
      </p>
      <h2>What data is collected ?</h2>
      <ul>
        <li>When you register on our website we collect your name, email address and your password on your account.</li>
        <li>When you browse our website we also collect information on the IP address and browser type being used.</li>
      </ul>
      <h2>How is that data used ?</h2>
      <ul>
        <li>
          To manage the accounts you have registered with us. All passwords are encrypted on the website and staff do
          not have access to that information at all.
        </li>
        <li>
          We only use contact information to supply event details to participants or to respond to communication that
          has been directed at us via email or social media.
        </li>
        <li>
          We only ever use contact information for marketing purposes when a user has not opted out of email
          communication after account registration.
        </li>
        <li>When you browse our website we also collect information on the IP address and browser type being used.</li>
      </ul>
      <h2>How is that data stored ?</h2>
      <ul>
        <li>Account information is securely stored on our website.</li>

        <li>
          If you wish to delete your data with us at any point then you can make that request via
          <a href="/contact/">contact</a>.
        </li>
        <li>Our website is regularly scanned to ensure that it is safe.</li>
      </ul>
      <h2>How is that data shared?</h2>
      <ul>
        <li>We will never sell your information or share it with other parties for marketing purposes.</li>
        <li>
          We would only ever share your personal information with another third party if we were legally compelled to
          do.
        </li>
      </ul>
    </template>
  </MoleculeText>
</template>
