<template>
  <div class="bg-gray-50 relative overflow-hidden">
    <div class="relative pb-12 pt-6 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
      <main class="mt-10 mx-auto px-4 max-w-screen-xl sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
        <div class="text-center">
          <h2
            class="
              text-gray-900 text-4xl
              font-extrabold
              tracking-tight
              leading-10
              sm:text-5xl
              sm:leading-none
              md:text-6xl
            "
          >
            Page Not Found!
          </h2>
          <p class="text-gray-500 mt-3 mx-auto max-w-md text-base sm:text-lg md:mt-5 md:max-w-3xl md:text-xl">
            Sorry we couldn't find the page you were looking for.
          </p>
          <div class="mt-5 mx-auto max-w-md sm:flex sm:justify-center md:mt-8">
            <div class="rounded-sm">
              <RouterLink
                to="/"
                class="
                  flex
                  items-center
                  justify-center
                  px-8
                  py-3
                  w-full
                  text-white text-base
                  font-medium
                  leading-6
                  hover:bg-orange-300
                  bg-orange-500
                  border border-transparent
                  rounded-sm
                  focus:outline-none
                  transition
                  duration-150
                  ease-in-out
                  md:px-10
                  md:py-4
                  md:text-lg
                "
              >
                Go to Home
              </RouterLink>
            </div>
            <div class="mt-3 rounded-sm sm:ml-3 sm:mt-0">
              <RouterLink
                to="/contact"
                class="
                  lg:hover:border-orange-300
                  lg:hover:bg-orange-300
                  lg:hover:text-white
                  flex
                  items-center
                  justify-center
                  px-8
                  py-3
                  w-full
                  text-black text-base
                  font-medium
                  leading-6
                  bg-white
                  border border-gray border-transparent
                  rounded-sm
                  focus:outline-none
                  transition
                  duration-300
                  ease-in-out
                  md:px-10
                  md:py-4
                  md:text-lg
                "
              >
                Contact us
              </RouterLink>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
