<template v-if="!isLoading">
  <div>
    <AtomSectionHeader classes="md:px-4 lg:px-8" :title="title" is-full-width>
      <form action="#" class="flex items-center mt-2 sm:mt-0" @submit.prevent="submitForm">
        <div class="relative w-full">
          <input
            v-model="searchTerm"
            type="text"
            class="
              form-input
              block
              pr-8
              px-2
              py-2
              w-full
              border-gray
              focus:border-orange-500
              rounded-sm
              focus:outline-none
              shadow-none
              transition
              duration-150
              ease-in-out
              focus:ring-0
            "
            placeholder="Find a user"
          />
          <button
            v-if="searchTerm"
            type="button"
            class="absolute bottom-0 right-0 top-0 px-2 h-10 cursor-pointer"
            @click.prevent="resetForm"
          >
            <BaseIcon width="16" height="16"><CloseIcon /></BaseIcon>
          </button>
        </div>
        <button class="lg:hover:text-orange-500 ml-2 cursor-pointer transition-colors duration-150 ease-in-out">
          <BaseIcon class="w-7 h-7"><SearchIcon /></BaseIcon>
        </button>
      </form>
    </AtomSectionHeader>

    <div class="mb-4 lg:mb-8">
      <OrganismMembers
        :members="members"
        @update-member="updateMemberFollowing"
        @update-members="getMembers(null, true)"
      />
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    inject: ['sidebar'],
    data() {
      return {
        title: 'Members',
        content: 'You can view all of our members below.',
        error: null,
        members: [],
        jwt: null,
        searchTerm: null,
        isLoadingNextPage: false,
        page: 1,
        perPage: 24
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        panelStatus: 'getPanelStatus',
        isLoading: 'getLoadingStatus',
        isBottom: 'getIsBottom'
      })
    },
    watch: {
      async isBottom() {
        if (this.isBottom) await this.getNextPage()
      }
    },
    mounted() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent() {
        this.$store.dispatch('setLoadingStatus', true)
        this.$store.dispatch('setIsTrackingScrollPos', true)
        await this.getMembers()
        this.$store.dispatch('setLoadingStatus', false)
      },
      async getMembers(query = null, ajaxLoad = false) {
        if (!ajaxLoad) this.$store.dispatch('setLoadingStatus', true)

        try {
          const members = await this.apiRequest(
            `/user/users`,
            null,
            query
              ? query
              : {
                  search: this.searchTerm ? this.searchTerm.toLowerCase() : null,
                  page: this.page,
                  perPage: this.perPage
                },
            {
              Authorization: this.userJWT
            }
          )

          if (members.members) {
            this.members = members.members.filter((member) => {
              return member.first_name != null && member.last_name != null
            })
          } else {
            this.members = []
          }

          this.page = 2
          if (members.total > this.members.length && !!!this.searchTerm) {
            this.$store.dispatch('setIsTrackingScrollPos', true)
          } else {
            this.$store.dispatch('setIsTrackingScrollPos', false)
          }
        } catch (e) {
          if (e) console.log(`Error getting users: ${e}`)
        }

        if (!ajaxLoad) this.$store.dispatch('setLoadingStatus', false)
      },
      async getNextPage() {
        const cta = event.target

        if (cta && cta.style) cta.style.width = `${cta.offsetWidth}px`
        this.isLoadingNextPage = true

        try {
          const members = await this.apiRequest(
            '/user/users',
            null,
            { search: this.searchTerm ? this.searchTerm.toLowerCase() : null, page: this.page, perPage: this.perPage },
            {
              Authorization: this.userJWT
            }
          )

          this.members = this.members.concat(members.members)

          this.members = this.members.filter((member) => {
            return member.first_name != null && member.last_name != null
          })

          this.page += 1
          if (this.members.length >= members.total) this.$store.dispatch('setIsTrackingScrollPos', false)
        } catch (e) {
          if (e) console.log(`Error getting members: ${e.status} ${e.data}`)
        }

        this.visible += this.perPage
        if (cta && cta.style) cta.style.width = null
        this.isLoadingNextPage = false
        this.$store.dispatch('setIsBottom', false)
      },
      async resetForm() {
        this.searchTerm = null

        this.getMembers(
          {
            page: 1
          },
          true
        )
      },
      async submitForm() {
        this.hideViewMore = true

        this.getMembers(
          {
            search: this.searchTerm ? this.searchTerm.toLowerCase() : null
          },
          true
        )
      },
      async updateMemberFollowing(userUid, isFollowing) {
        const user = this.members.find((user) => user.uid === userUid)

        user.is_following = isFollowing
      }
    }
  }
</script>
