<template>
  <MoleculeText title="Terms & Conditions">
    <template #default>
      <ol>
        <li>
          "Tracklimit Ltd" reserves the right to alter any of the information on its website without prior notice.
        </li>

        <li>
          "Tracklimit Ltd" or cannot be held responsible if the information on the website is found to be incorrrect.
        </li>

        <li>
          You accept that "Tracklimit Ltd" contains photographs and videos taken from a public event. Therefore images
          and/or videos of you or your property may appear on our websites, social media or in associated literature
          without your prior consent.
        </li>

        <li>These Terms and Conditions are governed by the jurisdiction of the laws of England & Wales</li>
      </ol>
    </template>
  </MoleculeText>
</template>
