<template v-if="!isLoading">
  <div>
    <div class="aspect-w-16 aspect-h-10 2xl:aspect-h-7 relative">
      <img
        v-if="user && coverPhotoUrl"
        :srcset="`${coverPhotoUrl}?w=1440&ar=1.6&fit=crop 1024w, ${coverPhotoUrl}?w=2560&ar=2.285&fit=crop 2560w`"
        sizes="(min-width: 48rem) 100vw, calc(100vw - 16rem)"
        :src="`${coverPhotoUrl}?w=2560&ar=2.285&fit=crop`"
        :alt="user.username"
        :class="[
          'absolute h-full w-full object-cover z-10 transform-gpu transition-all ease-in-out duration-300',
          !isImageLoaded ? 'opacity-0' : 'opacity-100'
        ]"
        @load="imageLoaded"
      />
      <div class="flex flex-col items-center justify-center bg-gray">
        <AtomLogo />
      </div>

      <AtomFollowUser v-if="!ownProfile && user" :user="user" />

      <RouterLink
        v-if="ownProfile"
        to="/account/settings"
        class="
          lg:hover:bg-orange-500
          absolute
          z-10
          bottom-auto
          left-auto
          right-4
          top-4
          flex
          items-center
          justify-center
          w-10
          h-10
          text-white
          border-0
          rounded-full
          transition
          duration-300
          ease-in-out
          lg:right-8
          lg:top-8
          lg:bg-black
        "
        title="Go to Settings"
      >
        <BaseIcon class="w-10">
          <EditIcon />
        </BaseIcon>
      </RouterLink>
    </div>
    <div class="mb-4">
      <MoleculeHero v-if="user" :title="name" icon="EventIcon" :content="`${numberofEvents} events`" />

      <section v-if="ownPhotographs">
        <div class="mx-auto w-full max-w-screen-2xl">
          <div class="px-4 lg:px-8">
            <h2 class="text-black text-xl font-extrabold leading-9 lg:text-2xl">Popular Photographs</h2>
          </div>
        </div>

        <ul class="grid gap-2 grid-cols-1 mt-2 px-4 lg:grid-cols-3 lg:mt-6 lg:px-8">
          <li v-for="photograph in popularPhotos" :key="photograph.uid">
            <AtomPhotograph :photograph="photograph" :hide-actions="true" />
          </li>
        </ul>
      </section>

      <section v-if="hasUpcomingEvents && user" class="mt-4 lg:mt-8">
        <div class="mx-auto w-full max-w-screen-2xl">
          <div class="px-4 lg:px-8">
            <h2 class="text-black text-xl font-extrabold leading-9 lg:text-2xl">Upcoming Events</h2>
          </div>
        </div>

        <div v-for="event in upcomingEvents" :key="event.uid" :class="['mt-4', event.series ? 'bg-gray' : null]">
          <div class="mx-auto w-full max-w-screen-2xl">
            <div class="px-4 lg:px-8">
              <h2
                v-if="event.circuit"
                class="flex items-center justify-between text-black text-xl font-extrabold leading-9"
              >
                <span v-if="event.series" class="flex justify-center">{{ event.series }}</span>
                <RouterLink v-if="event.circuit_slug" :to="`/circuit/${event.circuit_slug}`">
                  {{ event.circuit }}
                  <span v-if="event.layout !== 'Full Circuit'"> {{ event.layout }}</span>
                </RouterLink>
                <div v-else>
                  {{ event.circuit }}
                  <span v-if="event.layout !== 'Full Circuit'"> {{ event.layout }}</span>
                </div>
              </h2>

              <p
                v-if="event.start_date && event.slug"
                class="
                  lg:text-md
                  flex
                  items-center
                  justify-between
                  mx-auto
                  w-full
                  max-w-screen-2xl
                  text-black text-lg
                  leading-9
                "
              >
                <RouterLink v-if="event.slug" :to="`/event/${event.slug}`">
                  <AtomFormattedDate :date="event.start_date" />
                </RouterLink>
              </p>
              <p v-else-if="event.start_date">
                <AtomFormattedDate :date="event.start_date" />
              </p>
            </div>
          </div>
        </div>
      </section>

      <section v-if="hasPastEvents && user" class="mt-4 lg:mt-8">
        <div class="mx-auto w-full max-w-screen-2xl">
          <div class="px-4 lg:px-8">
            <h2 class="text-black text-xl font-extrabold leading-9 lg:text-2xl">Past Events</h2>
          </div>
        </div>

        <div v-for="event in pastEvents" :key="event.uid" :class="['mt-6', event.series ? 'bg-gray' : null]">
          <div class="mx-auto w-full max-w-screen-2xl">
            <div class="px-4 lg:px-8">
              <h2
                v-if="event.circuit"
                class="flex items-center justify-between text-black text-xl font-extrabold leading-9"
              >
                <span v-if="event.series" class="flex justify-center">{{ event.series }}</span>
                <RouterLink v-if="event.circuit_slug" :to="`/circuit/${event.circuit_slug}`">
                  {{ event.circuit }}
                  <span v-if="event.layout !== 'Full Circuit'"> {{ event.layout }}</span>
                </RouterLink>
                <div v-else>
                  {{ event.circuit }}
                  <span v-if="event.layout !== 'Full Circuit'"> {{ event.layout }}</span>
                </div>
              </h2>

              <p
                v-if="event.start_date && event.slug"
                class="
                  lg:text-md
                  flex
                  items-center
                  justify-between
                  mx-auto
                  w-full
                  max-w-screen-2xl
                  text-black text-lg
                  leading-9
                "
              >
                <RouterLink v-if="event.slug" :to="`/event/${event.slug}`">
                  <AtomFormattedDate :date="event.start_date" />
                </RouterLink>
              </p>
              <p v-else-if="event.start_date">
                <AtomFormattedDate :date="event.start_date" />
              </p>
            </div>
          </div>

          <ul v-if="event.race_results" class="mt-2 lg:mt-6">
            <li v-for="(result, index) in event.race_results" :key="index" class="mt-4">
              <p>{{ result.name }}</p>
              <p>{{ `${result.position}${nth(result.position)}` }}</p>
              <p>{{ result.summary }}</p>
            </li>
          </ul>

          <ul
            v-if="event.photographs"
            class="grid gap-2 grid-cols-1 2xl:grid-cols-3 3xl:grid-cols-4 mt-2 px-4 lg:grid-cols-2 lg:px-8"
          >
            <li v-for="photograph in event.photographs" :key="photograph.uid">
              <AtomPhotograph
                :link="true"
                :photograph="photograph"
                :event="event.uid"
                :hide-actions="false"
                :hide-tag-action="true"
                :hide-more-actions="true"
              />
            </li>
          </ul>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'
  import router from '@/router'

  export default {
    inject: ['sidebar'],
    data() {
      return {
        user: null,
        events: null,
        numberofEvents: 0,
        popularPhotos: null,
        isImageLoaded: false,
        pastEvents: null,
        upcomingEvents: null,
        userHeaders: null
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        isLoading: 'getLoadingStatus',
        loggedInUser: 'auth/getLoggedInUser',
        today: 'getToday'
      }),
      ownProfile() {
        if (this.user && this.loggedInUser) return this.user.username === this.loggedInUser.username

        return false
      },
      ownPhotographs() {
        if (this.popularPhotos && !!this.popularPhotos.length) return true
        return false
      },
      coverPhotoUrl() {
        return this.user && this.user.filename
          ? `${process.env.VUE_APP_IMGIX}/users/${this.user.uid}/${this.user.filename}`
          : null
      },
      currentPage() {
        return this.$route.path
      },
      name() {
        return this.user.first_name && this.user.last_name ? `${this.user.first_name} ${this.user.last_name}` : null
      },
      hasPastEvents() {
        return this.pastEvents && !!this.pastEvents.length
      },
      hasUpcomingEvents() {
        return this.upcomingEvents && !!this.upcomingEvents.length
      }
    },
    watch: {
      currentPage(n, o) {
        // if (n !== o) console.log(n)
      }
    },
    mounted() {
      if (this.sidebar) document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    created() {
      this.loadContent()
    },
    updated() {
      if (this.$route.params.username !== this.user.username) this.loadContent()
    },
    methods: {
      async loadContent() {
        if (this.userJWT) {
          this.userHeaders = {
            Authorization: this.userJWT
          }
        }

        await this.getUserByUsername()

        if (this.user) {
          await this.getUserEvents()
          // await this.getUserPopularPhotographs()
          await this.setUserEvents()
        }

        if (this.$route.query.u) this.showPhotograph(this.$route.query.u)
      },
      async getUserByUsername() {
        try {
          this.user = await this.apiRequest(`/user/${this.$route.params.username}`, null, null, this.userHeaders)

          if (!!!this.user) throw 'User not found'
        } catch (e) {
          router.push({ name: 'NotFound' })
        }
      },
      async getUserEvents() {
        try {
          this.events = await this.apiRequest(`/user/${this.user.uid}/events`)

          this.numberofEvents = this.events ? this.events.length : 0

          if (!!this.numberofEvents)
            await Promise.all(
              this.events.map(async (event) => {
                if (!!parseInt(event.albums)) {
                  event.photographs = await this.apiRequest(
                    `/user/${this.user.uid}/events/${event.uid}/photographs`,
                    null,
                    null,
                    this.userHeaders
                  )
                }
              })
            )
        } catch (e) {
          if (e) console.log(`Error getting user`)
          router.push({ name: 'NotFound' })
        }
      },
      async getUserPopularPhotographs() {
        try {
          const userPhotographs = await this.apiRequest(`/user/${this.user.uid}/photographs`)
          const mostLikedPhotographs = userPhotographs.filter((photo) => parseInt(photo.likes) > 0)

          this.popularPhotos = mostLikedPhotographs
        } catch (e) {
          if (e) console.log(`Error getting users popular photographs: ${e.status} ${e.data}`)
        }
      },
      async setUserEvents() {
        if (!this.events) return

        this.pastEvents = this.events.filter((event) => {
          return Date.parse(event.start_date) <= Date.now() ? event : false
        })

        this.upcomingEvents = this.events
          .filter((event) => {
            return Date.parse(event.start_date) - Date.parse(this.today) < 0 ? false : event
          })
          .reverse()
      },
      async showPhotograph(photographUid) {
        try {
          const photograph = await this.apiRequest(`/photograph/${photographUid}`, null, null, {
            Authorization: this.userJWT
          })

          this.$store.dispatch('setOverlay', {
            imagePath: `${process.env.VUE_APP_IMGIX}/${photograph.path}?w=1920&ar=1.5`,
            uid: photographUid,
            photograph_uid: photographUid,
            url: photographUid,
            liked: photograph.liked,
            tags: photograph.tags
          })
        } catch (e) {
          if (e) console.log(`Error getting photograph: ${e.status} ${e.data}`)
        }
      },
      imageLoaded() {
        this.isImageLoaded = true
      }
    }
  }
</script>
