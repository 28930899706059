<template v-if="!isLoading">
  <main>
    <div class="relative pt-20 bg-gray xl:pt-32">
      <div class="px-4 text-center">
        <div class="mx-auto px-5 max-w-xxs lg:px-0">
          <AtomBrand class="w-full" />
        </div>
        <p v-if="intro" class="mt-4 mx-auto px-4 w-full max-w-md text-lg md:max-w-3xl md:text-xl lg:mt-4">
          {{ intro }}
        </p>
        <AtomCTA class="mt-6" url="/join" title="Sign up now" inline />
      </div>

      <div class="relative z-10 mt-16 mx-auto px-4 max-w-screen-2xl lg:mt-24 lg:px-8">
        <div
          class="
            aspect-w-4 aspect-h-3
            relative
            flex
            items-center
            justify-center
            w-full
            h-full
            bg-white
            rounded-lg
            shadow-xl
            ring-black ring-opacity-5 ring-1
          "
        >
          <img
            class="absolute z-10 w-full h-full object-cover transform-gpu"
            :class="[!isImageLoaded ? 'opacity-0' : 'opacity-100']"
            src="https://tracklimit.imgix.net/generic/dashboard.webp"
            alt="Dashboard"
            @load="imageLoaded"
          />
          <div
            :class="[
              'text-black flex items-center justify-center transform-gpu transition-all ease-in-out duration-300',
              isImageLoaded ? 'opacity-0' : 'opacity-100'
            ]"
          >
            <AtomLogo :width="70" :height="42" class="animate-pulse" />
          </div>
        </div>
      </div>
      <div class="absolute bottom-0 left-0 right-0 w-full h-32 bg-white lg:h-64"></div>
    </div>

    <section class="mt-8 mx-auto w-full max-w-screen-2xl text-base lg:mt-16 lg:text-lg">
      <div class="space-y-8 lg:space-y-16">
        <article v-if="events && !!events.length" class="grid gap-4 px-4 md:grid-cols-2 md:items-center lg:px-8">
          <div class="text-center space-y-4 md:px-4 lg:px-8">
            <h2 class="text-2xl font-bold tracking-tight leading-tight lg:text-4xl">Find track days to attend</h2>
            <p>Browse events from all of the major UK track day organisers or add track days you've already been to.</p>
          </div>

          <MoleculeEventList
            class="border border-b-0 border-gray md:col-start-1 md:row-start-1 lg:border-b"
            :events="events"
            :preview="true"
          />
        </article>

        <article v-if="video" class="grid gap-4 px-4 md:grid-cols-2 md:items-center lg:px-8">
          <div class="text-center space-y-4 md:px-4 lg:px-8">
            <h2 class="text-2xl font-bold tracking-tight leading-tight lg:text-4xl">Store all your data</h2>
            <p>Upload photos, add videos, save lap times and create leaderboards between friends.</p>
          </div>

          <div class="aspect-w-16 aspect-h-9 block w-full rounded-sm overflow-hidden">
            <AtomVideo v-if="video" :video="video" class="rounded-sm overflow-hidden" />
          </div>
        </article>

        <article v-if="randomPhotoUrl" class="grid gap-4 px-4 md:grid-cols-2 md:items-center lg:px-8">
          <div class="text-center space-y-4 md:px-4 lg:px-8">
            <h2 class="text-2xl font-bold tracking-tight leading-tight lg:text-4xl">Talk all things track</h2>
            <p>Chat about events, photographs, videos and lap times with other members.</p>
          </div>

          <div
            class="
              aspect-w-3 aspect-h-2
              block
              w-full
              border border-gray
              rounded-sm
              overflow-hidden
              md:col-start-1
              md:row-start-1
            "
          >
            <img
              v-if="randomPhotoUrl"
              :class="[
                'absolute h-full w-full object-cover z-10 transform-gpu transition-all ease-in-out duration-300',
                !isImageLoaded ? 'opacity-0' : 'opacity-100'
              ]"
              :src="randomPhotoUrl"
              alt="Talk all things track"
              @load="imageLoaded"
            />
            <div
              class="
                lg:group-hover:bg-orange-500 lg:group-hover:text-white
                flex
                items-center
                justify-center
                bg-gray
                transition-colors
                duration-300
                ease-in-out
              "
            >
              <AtomLogo />
            </div>
          </div>
        </article>
      </div>
    </section>

    <div class="mt-8 text-base lg:mt-20 lg:text-xl">
      <div class="relative px-6 py-8 text-white bg-orange-500 sm:px-12 sm:py-24">
        <div class="text-center">
          <AtomLogo class="mx-auto w-full" :height="48" :width="48" />
          <h2 class="mt-4 text-3xl font-bold tracking-tight leading-tight lg:text-5xl">Join the community</h2>
          <p class="mt-2 mx-auto w-full max-w-md md:max-w-3xl">
            Sign up now and start building your Tracklimit profile
          </p>
          <div class="text-center lg:text-base">
            <AtomCTA class="mt-6" url="/join" title="Sign up now" secondary large inline />
          </div>
        </div>
      </div>
    </div>

    <div v-if="interactiveMembers && !!interactiveMembers.length" class="text-base lg:text-xl">
      <section class="grid gap-4 md:items-center lg:gap-8">
        <div class="grid gap-0 grid-cols-2 2xl:grid-cols-6 md:grid-cols-3">
          <MoleculeMember
            v-for="(member, i) in interactiveMembers"
            :key="member.uid"
            :index="i"
            :user="member"
            is-simple
            :medium="8"
          />
        </div>
      </section>
    </div>
  </main>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {},
    data() {
      return {
        events: null,
        interactiveMembers: null,
        intro: 'Find, store and talk all things track',
        isImageLoaded: false,
        members: null,
        photo: null,
        video: null
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        isLoading: 'getLoadingStatus'
      }),
      randomPhotoUrl() {
        return this.photo ? `${process.env.VUE_APP_IMGIX}/${this.photo.path}?w=600&ar=3:2` : null
      }
    },

    created() {
      this.loadContent()
    },
    methods: {
      async loadContent() {
        this.$store.dispatch('setLoadingStatus', true)
        await Promise.all([this.getUpcomingEvents(), this.getMembers(), this.getRandomPhoto(), this.getRandomVideo()])
        this.$store.dispatch('setLoadingStatus', false)
      },
      async getMembers() {
        try {
          this.members = await this.apiRequest(`/user/users/complete`)
          this.interactiveMembers = await this.apiRequest(`/user/users/complete`)
        } catch (e) {
          if (e) console.log(`Error getting users: ${e.status} ${e.data}`)
        }
      },
      async getUpcomingEvents() {
        try {
          this.events = await this.apiRequest(`/event/events/upcoming`)
        } catch (e) {
          if (e) console.log(`Error getting upcoming events: ${e.status} ${e.data}`)
        }
      },
      async getRandomPhoto() {
        try {
          this.photo = await this.apiRequest(`/photograph/random`)
        } catch (e) {
          if (e) console.log(`Error getting random photograph: ${e.status} ${e.data}`)
        }
      },
      async getRandomVideo() {
        try {
          this.video = await this.apiRequest(`/video/random`)
        } catch (e) {
          if (e) console.log(`Error getting random video: ${e.status} ${e.data}`)
        }
      },
      imageLoaded() {
        this.isImageLoaded = true
      }
    }
  }
</script>
