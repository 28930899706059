<template v-if="!isLoading">
  <div>
    <AtomSectionHeader
      classes="md:px-4 lg:px-8"
      :title="title"
      icon="PlusIcon"
      :action-title="actionTitle"
      @action="buildForm"
    />

    <OrganismCircuitLayouts :circuits="circuits" @update-laps="updateCircuitLapList" />
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    inject: ['sidebar'],
    data() {
      return {
        title: 'Lap times',
        actionTitle: 'Add a lap time',
        circuitLayouts: null,
        events: null,
        circuits: null,
        form: {
          cta: 'Add lap time',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          fields: [
            {
              id: 'event_uid',
              label: 'Event',
              elem: 'select',
              isRequired: null,
              classes: 'sm:col-span-2',
              children: [
                {
                  tag: 'option',
                  text: 'None',
                  value: 'none'
                }
              ],
              instruction: {
                content: "Can't see the event you've attended?",
                url: '/events',
                cta: 'You can add a past event.'
              }
            },
            {
              id: 'circuit_uid',
              label: 'Circuit',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'layout_uid',
              label: 'Layout',
              elem: 'select',
              isRequired: 'true',
              children: [],
              disabled: true
            },
            {
              id: 'car_uid',
              label: 'Car',
              elem: 'select',
              isRequired: 'true',
              children: [],
              classes: 'sm:col-span-2',
              instruction: {
                content: "Don't have any cars saved?",
                url: '/dashboard/cars',
                cta: 'Add a car.'
              }
            },
            {
              id: 'date',
              label: 'Date',
              elem: 'input',
              type: 'date',
              min: '2000-01-01',
              max: `${new Date().getFullYear()}-12-31`,
              isRequired: 'true'
            },
            {
              id: 'weather',
              label: 'Weather Conditions',
              elem: 'select',
              children: [
                {
                  tag: 'option',
                  text: 'Dry',
                  value: 'dry'
                },
                {
                  tag: 'option',
                  text: 'Wet',
                  value: 'wet'
                }
              ]
            },
            {
              id: 'laptime',
              label: 'Lap Time',
              placeholder: '00:00.000',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              instruction: {
                content: 'Format minutes:seconds.milliseconds'
              }
            },
            // {
            //   id: 'video_uid',
            //   label: 'Existing Video',
            //   elem: 'select',
            //   isRequired: null,
            //   children: [],
            //   instruction: {
            //     content: 'You can either select an existing video or add the YouTube URL for a new video'
            //   }
            // },
            {
              id: 'youtube_id',
              label: 'YouTube Video URL',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'private',
              label: 'Do not display this lap time publicly',
              elem: 'input',
              type: 'checkbox',
              classes: 'sm:col-span-2 flex justify-end flex-row-reverse'
            }
          ]
        },
        jwt: null,
        cars: null
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        loggedInUser: 'auth/getLoggedInUser',
        panelStatus: 'getPanelStatus',
        isLoading: 'getLoadingStatus',
        allCircuits: 'circuits/getCircuits',
        today: 'getToday'
      })
    },
    watch: {
      async panelStatus(res) {
        if (res) {
          this.loadContent()
        }
      }
    },
    mounted() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent() {
        this.$store.dispatch('setLoadingStatus', true)
        await this.getCircuitLayouts()
        await Promise.all([this.getCircuitLayoutLaptimes()])
        if (this.$route.query.p) await this.buildForm()
        this.$store.dispatch('setLoadingStatus', false)
      },
      async getCircuitLayouts() {
        const groupBy = (x, f) => x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {})

        try {
          this.circuitLayouts = await this.apiRequest(`/circuit/circuit-layouts`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          if (e) console.log(`Error getting circuit layouts: ${e.status} ${e.data}`)

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: `<p>Sorry we're having issues retrieving circuit layouts.</p>`,
            ctaTitle: 'Close',
            error: true
          })
        }

        this.circuits = groupBy(this.circuitLayouts, (c) => c.circuit)
      },
      async getCircuitLayoutLaptimes() {
        this.circuitLayouts.forEach(async (circuitLayout) => {
          try {
            const laptimes = await this.apiRequest(`/laptime/laptimes/${circuitLayout.uid}`, null, null, {
              Authorization: this.userJWT
            })

            laptimes.sort((a, b) => a.laptime - b.laptime)

            const circuit = this.circuitLayouts.find((c) => c.uid === circuitLayout.uid)

            circuit.laptimes = laptimes
          } catch (e) {
            if (e) console.log(`Error getting laptimes for ${circuitLayout.uid}`)
          }
        })
      },
      async updateCircuitLapList(lapUid) {
        this.loadContent()
      },
      async buildForm() {
        // Reset form data
        Object.assign(this.$data.form, this.$options.data().form)

        await Promise.all([
          this.$store.dispatch('circuits/getCircuits'),
          this.getUserCars(),
          this.getUserEvents(),
          this.getUserVideos()
        ])

        this.setCircuits()
        this.setEvents()
        this.setCars()
        this.setMaxDate()

        if (this.$route.query.p) {
          let queries = JSON.parse(JSON.stringify(this.$route.query))
          queries.p = undefined

          this.$router.replace({ query: queries })
        }

        this.$store.dispatch('setPanel', {
          title: this.actionTitle,
          form: this.form,
          endpoint: '/laptime',
          errorMessage: "Sorry we couldn't add your lap time.",
          successMessage: 'Your lap time has been added.'
        })
      },
      async getUserCars() {
        try {
          this.cars = await this.apiRequest(`/user/${this.loggedInUser.id}/cars`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          if (e) console.log(`Error getting cars: ${e.status} ${e.data}`)
        }
      },
      async getUserEvents() {
        try {
          this.events = await this.apiRequest(
            `/user/${this.loggedInUser.id}/events`,
            null,
            { basic: true, start: '2000-01-01', end: this.today },
            {
              Authorization: this.userJWT
            }
          )
        } catch (e) {
          if (e) console.log('Error getEvents')
        }
      },
      async getUserVideos() {
        try {
          this.videos = await this.apiRequest(`/user/${this.loggedInUser.id}/videos`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          if (e) console.log('Error getVideos')
        }
      },
      async setCars() {
        const carFormField = this.form.fields.find((field) => field.id === 'car_uid')

        this.cars.forEach((car) => {
          carFormField.children.push({
            tag: 'option',
            text: `${car.car_name} (${car.manufacturer} - ${car.model})`,
            value: car.uid
          })
        })
      },
      async setEvents() {
        const eventFormField = this.form.fields.find((field) => field.id === 'event_uid')
        const pastEvents = this.events.filter((event) => {
          return Date.parse(event.start_date) <= Date.now() ? event : null
        })
        const re = /(\d{4})[-. \/](\d\d)[-. \/](\d\d)/

        pastEvents.forEach((event) => {
          const date = event.start_date.slice(0, 10).replace(re, '$3/$2/$1')
          let text = `${date} - ${event.circuit} `

          if (event.slug.includes('evening')) text += `- Evening `
          text += `(${event.organiser})`

          eventFormField.children.push({
            tag: 'option',
            text,
            value: event.uid
          })
        })
      },
      async setCircuits() {
        const circuitFormField = this.form.fields.find((field) => field.id === 'circuit_uid')

        this.allCircuits.forEach((circuit) => {
          circuitFormField.children.push({
            tag: 'option',
            text: circuit.name,
            value: circuit.uid
          })
        })
      },
      async setMaxDate() {
        const date = this.form.fields.find((field) => field.id === 'date')

        date.max = this.today
      }
    }
  }
</script>
