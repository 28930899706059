<template v-if="!isLoading">
  <div>
    <AtomSectionHeader
      classes="md:px-4 lg:px-8"
      :title="title"
      icon="PlusIcon"
      :action-title="actionTitle"
      is-full-width
      @action="buildForm"
    />
    <div class="mb-4 lg:mb-8">
      <OrganismVideos :videos="videos" :filters="filters" @update-content="getVideos" @delete-video="updateVideoList" />
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    inject: ['sidebar'],
    data() {
      return {
        title: 'Videos',
        actionTitle: 'Add a video',
        jwt: null,
        videos: null,
        cars: null,
        isLoadingNextPage: false,
        page: 1,
        perPage: 12,
        selectedFilters: null,
        form: {
          cta: 'Add video',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          fields: [
            {
              id: 'name',
              label: 'Name',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'description',
              label: 'Description',
              elem: 'textarea',
              type: 'text',
              isRequired: null,
              classes: 'sm:col-span-2'
            },
            {
              id: 'event_uid',
              label: 'Event',
              elem: 'select',
              isRequired: null,
              classes: 'sm:col-span-2',
              children: [
                {
                  tag: 'option',
                  text: 'None',
                  value: 'none'
                }
              ],
              instruction: {
                content: "Can't see the event you've attended?",
                url: '/events',
                cta: 'You can add a past event.'
              }
            },
            {
              id: 'circuit_uid',
              label: 'Circuit',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'layout_uid',
              label: 'Layout',
              elem: 'select',
              isRequired: 'true',
              children: [],
              disabled: true
            },
            {
              id: 'date',
              label: 'Date',
              elem: 'input',
              type: 'date',
              min: '2000-01-01',
              max: `${new Date().getFullYear()}-12-31`,
              isRequired: 'true'
            },
            {
              id: 'youtube_id',
              label: 'YouTube Video URL',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'car_uid',
              label: 'Car',
              elem: 'select',
              isRequired: 'true',
              children: [],
              classes: 'sm:col-span-2',
              children: [
                {
                  tag: 'option',
                  text: 'None',
                  value: 'none'
                }
              ],
              instruction: {
                content: "Don't have any cars saved?",
                url: '/dashboard/cars',
                cta: 'Add a car.'
              }
            }
          ]
        },
        filters: {
          fields: [
            {
              id: 'circuit',
              label: 'Circuit',
              elem: 'select',
              isRequired: 'true',
              children: [],
              defaultAll: true,
              classes: 'flex-auto w-full mt-4 lg:mr-4 lg:mt-0'
            },
            {
              id: 'friends_only',
              label: 'Friends only',
              elem: 'checkbox',
              isRequired: null,
              classes: 'flex-auto flex-shrink-0 mt-4 lg:mt-0'
            }
          ]
        }
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        panelStatus: 'getPanelStatus',
        isLoading: 'getLoadingStatus',
        loggedInUser: 'auth/getLoggedInUser',
        circuits: 'circuits/getCircuits',
        today: 'getToday',
        isBottom: 'getIsBottom'
      }),
      isAdmin() {
        return this.loggedInUser && this.loggedInUser.admin
      }
    },
    watch: {
      async panelStatus(res) {
        if (res) await this.getVideos(this.selectedFilters)
      },
      '$route.query.u'() {
        if (this.$route.query && this.$route.query.u) this.showVideo(this.$route.query.u)
      },
      async isBottom() {
        if (this.isBottom) await this.getNextPage()
      }
    },
    mounted() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent() {
        this.$store.dispatch('setLoadingStatus', true)
        this.$store.dispatch('setIsTrackingScrollPos', true)
        await Promise.all([this.getCircuitsWithVideos()])
        if (this.$route.query.u) this.showVideo(this.$route.query.u)
        this.$store.dispatch('setLoadingStatus', false)
      },
      async getCircuitsWithVideos() {
        const circuits = await this.apiRequest(
          '/circuit/circuits',
          null,
          { videos: true },
          {
            Authorization: this.userJWT
          }
        )
        const circuitFilterField = this.filters.fields.find((field) => field.id === 'circuit')

        circuits.forEach((circuit) => {
          circuitFilterField.children.push({
            tag: 'option',
            text: circuit.name,
            value: circuit.name
          })
        })
      },
      async getVideos(filters) {
        await Promise.all([this.$store.dispatch('circuits/getCircuits')])

        this.selectedFilters = filters

        try {
          const circuits =
            filters && filters.circuit
              ? [this.circuits.find((circuit) => circuit.name === filters.circuit).name]
              : this.circuits
              ? this.circuits.map((circuit) => circuit.name)
              : null

          const videos = await this.apiRequest(
            '/video/videos',
            null,
            { circuits: JSON.stringify(circuits), friends_only: filters && filters.friends_only ? true : null },
            {
              Authorization: this.userJWT
            }
          )

          this.videos = videos.videos
          this.page = 2
          if (videos.total > this.videos.length) this.$store.dispatch('setIsTrackingScrollPos', true)
        } catch (e) {
          if (e) console.log(`Error getting videos: ${e}`)
        }
      },
      async getNextPage() {
        const filters = this.selectedFilters
        const cta = event.target

        if (cta && cta.style) cta.style.width = `${cta.offsetWidth}px`
        this.isLoadingNextPage = true

        try {
          const circuits =
            filters && filters.circuit
              ? [this.circuits.find((circuit) => circuit.name === filters.circuit).name]
              : this.circuits
              ? this.circuits.map((circuit) => circuit.name)
              : null

          const videos = await this.apiRequest(
            `/video/videos`,
            null,
            {
              circuits: JSON.stringify(circuits),
              friends_only: filters && filters.friends_only,
              page: this.page,
              perPage: this.perPage
            },
            {
              Authorization: this.userJWT
            }
          )

          this.videos = this.videos.concat(videos.videos)
          this.page += 1
          if (this.videos.length >= videos.total) this.$store.dispatch('setIsTrackingScrollPos', false)
        } catch (e) {
          if (e) console.log(`Error getting next page: ${e}`)
        }

        if (cta && cta.style) cta.style.width = null
        this.isLoadingNextPage = false
        this.$store.dispatch('setIsBottom', false)
      },
      async buildForm() {
        // Reset form data
        Object.assign(this.$data.form, this.$options.data().form)

        await Promise.all([this.$store.dispatch('circuits/getCircuits'), this.getEvents(), this.getUserCars()])

        this.setCircuits()
        this.setEvents()
        this.setCars()
        this.setMaxDate()

        this.$store.dispatch('setPanel', {
          title: this.actionTitle,
          form: this.form,
          endpoint: '/video',
          errorMessage: "Sorry we couldn't add your video.",
          successMessage: 'Your video has been added.'
        })
      },
      async getEvents() {
        try {
          this.events = await this.apiRequest(
            `/user/${this.loggedInUser.id}/events`,
            null,
            { basic: true, start: '2000-01-01', end: this.today },
            {
              Authorization: this.userJWT
            }
          )
        } catch (e) {
          if (e) console.log('Error getEvents')
        }
      },
      async getUserCars() {
        try {
          this.cars = await this.apiRequest(`/user/${this.loggedInUser.id}/cars`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          if (e) console.log(`Error getting cars: ${e.status} ${e.data}`)
        }
      },
      async setCars() {
        const carFormField = this.form.fields.find((field) => field.id === 'car_uid')

        this.cars.forEach((car) => {
          carFormField.children.push({
            tag: 'option',
            text: `${car.car_name} (${car.manufacturer} - ${car.model})`,
            value: car.uid
          })
        })
      },
      async setEvents() {
        const eventFormField = this.form.fields.find((field) => field.id === 'event_uid')
        const pastEvents = this.events.filter((event) => {
          return Date.parse(event.start_date) <= Date.now() ? event : null
        })
        const re = /(\d{4})[-. \/](\d\d)[-. \/](\d\d)/

        pastEvents.forEach((event) => {
          const date = event.start_date.slice(0, 10).replace(re, '$3/$2/$1')
          let text = `${date} - ${event.circuit} `

          if (event.slug.includes('evening')) text += `- Evening `
          text += `(${event.organiser})`

          eventFormField.children.push({
            tag: 'option',
            text,
            value: event.uid
          })
        })
      },
      async setCircuits() {
        const circuitFormField = this.form.fields.find((field) => field.id === 'circuit_uid')

        this.circuits.forEach((circuit) => {
          circuitFormField.children.push({
            tag: 'option',
            text: circuit.name,
            value: circuit.uid
          })
        })
      },
      async setMaxDate() {
        const date = this.form.fields.find((field) => field.id === 'date')

        date.max = this.today
      },
      async updateVideoList(videoUid) {
        this.videos = this.videos.filter((video) => {
          return video.uid !== videoUid
        })
      },
      async showVideo(videoUid) {
        const video = this.videos ? this.videos.find((video) => video.uid === videoUid) : null

        if (!!!video)
          try {
            const video = await this.apiRequest(`/video/${videoUid}`, null, null, {
              Authorization: this.userJWT
            })
            const data = {
              youtube_id: video.youtube_id,
              uid: video.uid,
              url: video.uid,
              owner_uid: video.owner_uid,
              title: `${video.circuit_name} - ${video.layout_name}`,
              content: video.description,
              liked: video.liked,
              type: 'video',
              meta: {
                username: video.username,
                first_name: video.first_name,
                last_name: video.last_name,
                date: video.event_date,
                url: video.event_slug,
                lap_time: video.lap_time,
                car: {
                  name: video.car_name,
                  description: video.car_description
                }
              }
            }

            this.$store.dispatch('setOverlay', data)
          } catch (e) {
            if (e) console.log(`Error getting video: ${e.status} ${e.data}`)
          }
      }
    }
  }
</script>
