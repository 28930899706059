<template v-if="!isLoading">
  <div class="mb-4 lg:mb-8">
    <MoleculeCover :cover-photo-url="coverPhotoUrl" @show-panel="buildEditForm()" />

    <AtomPageTitle v-if="isOrganiser" :title="organiser.name" class="border-b border-gray" />

    <div class="mb-4 divide-gray divide-y space-y-4">
      <section v-if="hasEvents" :class="[!hideEventsViewMore ? 'space-y-4 mb-4 lg:mb-8' : null]">
        <AtomSectionHeader
          classes="md:px-4 lg:px-8"
          title="Events"
          icon="PlusIcon"
          :action-title="loggedInUser.admin ? 'Add an event' : 'Add a past event'"
          @action="buildForm()"
        />
        <OrganismEvents :events="events" />
        <button
          v-if="!hideEventsViewMore && !isLoadingEvents"
          type="submit"
          :class="[
            'flex mx-auto mt-4 items-center justify-center border border-gray w-full lg:w-auto py-2 px-4 rounded-sm transition-colors ease-in-out duration-300 lg:hover:bg-orange-500 lg:hover:text-white lg:hover:border-orange-500 focus:outline-none focus:ring-orange-500 focus:border-orange-500',
            !isLoadingNextEventsPage ? 'lg:hover:bg-orange-300' : 'bg-orange-500 cursor-default'
          ]"
          @click.prevent="getEvents()"
        >
          <span v-if="!isLoadingNextEventsPage" class="pointer-events-none"> Show more </span>
          <AtomLoader v-else></AtomLoader>
        </button>
      </section>

      <section v-if="hasAlbums">
        <AtomSectionHeader classes="md:px-4 lg:px-8" title="Albums" heading-tag="h2" @action="buildForm" />
        <OrganismAlbums on-organiser centered :albums="albums" />
        <button
          v-if="!hideAlbumsViewMore && !isLoadingAlbums"
          type="submit"
          :class="[
            'flex mx-auto mt-4 items-center justify-center border border-gray w-full lg:w-auto py-2 px-4 rounded-sm transition-colors ease-in-out duration-300 lg:hover:bg-orange-500 lg:hover:text-white lg:hover:border-orange-500 focus:outline-none focus:ring-orange-500 focus:border-orange-500',
            !isLoadingNextAlbumsPage ? 'lg:hover:bg-orange-300' : 'bg-orange-500 cursor-default'
          ]"
          @click.prevent="getAlbums()"
        >
          <span v-if="!isLoadingNextAlbumsPage" class="pointer-events-none"> Show more </span>
          <AtomLoader v-else></AtomLoader>
        </button>
      </section>

      <section v-if="organiser">
        <AtomSectionHeader classes="md:px-4 lg:px-8" title="Comments" heading-tag="h2" />
        <OrganismComments :comments="comments" :content-uid="organiser.uid" @update-comments="updateCommentList" />
      </section>
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    inject: ['sidebar'],
    props: {},
    data() {
      return {
        organiser: null,
        events: [],
        albums: [],
        comments: null,
        isSaving: false,
        eventsPage: 1,
        eventsPerPage: 10,
        hideEventsViewMore: false,
        isLoadingNextEventsPage: null,
        isLoadingEvents: true,
        albumsPage: 1,
        albumsPerPage: 6,
        hideAlbumsViewMore: false,
        isLoadingNextAlbumsPage: null,
        isLoadingAlbums: true,
        actionTitle: 'Add event',
        editActionTitle: 'Update organiser',
        form: {
          cta: 'Add event',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          fields: [
            {
              id: 'circuit_uid',
              label: 'Circuit',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'layout_uid',
              label: 'Layout',
              elem: 'select',
              isRequired: 'true',
              children: [],
              disabled: true
            },
            {
              id: 'organiser_uid',
              label: 'Organiser',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'format_uid',
              label: 'Format',
              elem: 'select',
              isRequired: 'true',
              children: []
            },
            {
              id: 'start_date',
              label: 'Date',
              elem: 'input',
              type: 'date',
              min: '2000-01-01',
              max: `${new Date().getFullYear()}-12-31`,
              isRequired: 'true'
            },
            {
              id: 'url',
              label: 'Event URL',
              elem: 'input',
              type: 'text',
              isRequired: null
            }
          ]
        }
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        panelStatus: 'getPanelStatus',
        isLoading: 'getLoadingStatus',
        loggedInUser: 'auth/getLoggedInUser',
        circuits: 'circuits/getCircuits',
        organisers: 'organisers/getOrganisers',
        formats: 'formats/getFormats',
        today: 'getToday'
      }),
      hasAlbums() {
        return !!this.albums && !!this.albums.length
      },
      isOrganiser() {
        return !!this.organiser
      },
      hasEvents() {
        return !!this.events
      },
      editForm() {
        return {
          cta: 'Update organiser',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          initial: {
            name: this.organiser.name,
            address_1: this.organiser.address_1,
            address_2: this.organiser.address_2,
            address_3: this.organiser.address_3,
            town_city: this.organiser.town_city,
            post_code: this.organiser.post_code,
            country: this.organiser.country,
            email: this.organiser.email,
            telephone: this.organiser.telephone
          },
          fields: [
            {
              id: 'name',
              label: 'Name',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'address_1',
              label: 'Address Line 1',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'address_2',
              label: 'Address Line 2',
              elem: 'input',
              type: 'text',
              isRequired: null,
              classes: 'sm:col-span-2'
            },
            {
              id: 'address_3',
              label: 'Address Line 3',
              elem: 'input',
              type: 'text',
              isRequired: null,
              classes: 'sm:col-span-2'
            },
            {
              id: 'town_city',
              label: 'Town',
              elem: 'input',
              type: 'text',
              isRequired: null
            },
            {
              id: 'post_code',
              label: 'Postcode',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'country',
              label: 'Country',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'email',
              label: 'Email Address',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'telephone',
              label: 'Phone Number',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            }
          ]
        }
      },
      coverPhotoUrl() {
        return this.organiser && this.organiser.hero ? `${process.env.VUE_APP_IMGIX}/${this.organiser.hero.path}` : null
      }
    },
    watch: {
      async panelStatus(res) {
        if (res && res.type !== 'event') {
          this.$router.replace({
            path: `/organiser/${res.slug}`
          })
          await Promise.all([this.getOrganiserBySlug(res.slug), this.getHero()])
        }

        if (res && res.type === 'event' && res.slug) {
          this.$router.push({
            path: `/event/${res.slug}`
          })
        }
      }
    },
    mounted() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent(slug) {
        this.$store.dispatch('setLoadingStatus', true)

        try {
          await this.getOrganiserBySlug(slug)
        } catch (e) {
          console.log('Organiser not found')
        }

        await Promise.all([this.$store.dispatch('circuits/getCircuits')])

        await Promise.all([this.getHero(), this.getEvents(), this.getAlbums(), this.getComments()])
        this.$store.dispatch('setLoadingStatus', false)
      },
      async getOrganiserBySlug(slug = this.$route.params.slug) {
        try {
          this.organiser = await this.apiRequest(`/organiser/${slug}`, null, null, { Authorization: this.userJWT })
        } catch (e) {
          this.$router.push({ name: 'NotFound' })
          if (e) console.log(`Error getting organiser: ${e.status} ${e.data}`)
        }
      },
      async getHero() {
        try {
          this.organiser.hero = await this.apiRequest(
            `/organiser/${this.organiser.uid}/photographs/popular`,
            null,
            null,
            { Authorization: this.userJWT }
          )
        } catch (e) {
          if (e) console.log(`Error getting hero: ${e.status} ${e.data}`)
        }
      },
      async getEvents() {
        const cta = event.target

        if (cta && cta.style) cta.style.width = `${cta.offsetWidth}px`
        this.isLoadingNextEventsPage = true

        try {
          const events = await this.apiRequest(
            `/organiser/${this.organiser.uid}/events`,
            null,
            {
              start: this.today,
              end: `${new Date().getFullYear()}-12-31`,
              page: this.eventsPage,
              perPage: this.eventsPerPage
            },
            { Authorization: this.userJWT }
          )

          this.events = this.events.concat(events.events)
          if (this.events.length >= parseInt(events.total)) this.hideEventsViewMore = true
          this.eventsPage += 1
          this.isLoadingEvents = false
        } catch (e) {
          if (e) console.log(`Error getting events: ${e}`)
        }

        if (cta && cta.style) cta.style.width = null
        this.isLoadingNextEventsPage = false
      },
      async getAlbums() {
        const cta = event.target

        if (cta && cta.style) cta.style.width = `${cta.offsetWidth}px`
        this.isLoadingNextAlbumsPage = true

        try {
          const albums = await this.apiRequest(
            `/organiser/${this.organiser.uid}/albums`,
            null,
            {
              page: this.albumsPage,
              perPage: this.albumsPerPage
            },
            { Authorization: this.userJWT }
          )

          this.albums = this.albums.concat(albums.albums)
          if (this.albums.length >= parseInt(albums.total)) this.hideAlbumsViewMore = true
          this.albumsPage += 1
          this.isLoadingAlbums = false
        } catch (e) {
          if (e) console.log(`Error getting albums: ${e}`)
        }

        if (cta && cta.style) cta.style.width = null
        this.isLoadingNextAlbumsPage = false
      },
      async getComments() {
        const headers = {}

        if (this.userJWT) headers.Authorization = this.userJWT

        try {
          this.comments = await this.apiRequest('/comment/comments', null, { u: this.organiser.uid }, headers)
        } catch (e) {
          if (e) console.log(`Error getting comments: ${e.status} ${e.data}`)
        }
      },
      async buildForm() {
        // Reset form data
        Object.assign(this.$data.form, this.$options.data().form)

        await Promise.all([
          this.$store.dispatch('organisers/getOrganisers'),
          this.$store.dispatch('formats/getFormats')
        ])

        this.setCircuits()
        this.setOrganisers()
        this.setFormats()
        this.setMaxDate()

        this.$store.dispatch('setPanel', {
          title: this.actionTitle,
          form: this.form,
          endpoint: '/event',
          errorMessage: "Sorry we couldn't add the event."
        })
      },
      async buildEditForm() {
        this.$store.dispatch('setPanel', {
          title: this.editActionTitle,
          form: this.editForm,
          method: 'PUT',
          endpoint: `/organiser/${this.organiser.uid}`,
          errorMessage: "Sorry we couldn't update the organiser.",
          successMessage: 'The organiser has been updated.'
        })
      },
      async setCircuits() {
        const circuitFormField = this.form.fields.find((field) => field.id === 'circuit_uid')

        this.circuits.forEach((circuit) => {
          circuitFormField.children.push({
            tag: 'option',
            text: circuit.name,
            value: circuit.uid
          })
        })
      },
      async setOrganisers() {
        const organiserFormField = this.form.fields.find((field) => field.id === 'organiser_uid')

        organiserFormField.children.push({
          tag: 'option',
          text: this.organiser.name,
          value: this.organiser.uid
        })

        organiserFormField.selected = this.organiser.uid
      },
      async setFormats() {
        const formatFormField = this.form.fields.find((field) => field.id === 'format_uid')

        this.formats.forEach((format) => {
          formatFormField.children.push({
            tag: 'option',
            text: format.name,
            value: format.uid
          })
        })
      },
      async setMaxDate() {
        const start_date = this.form.fields.find((field) => field.id === 'start_date')

        start_date.max = this.loggedInUser.admin ? '2030-12-31' : this.today
      },
      async updateCommentList(commentUid) {
        if (commentUid) {
          this.comments = this.comments.filter((comment) => {
            return comment.uid !== commentUid
          })
        } else {
          this.getComments()
        }
      }
    }
  }
</script>
