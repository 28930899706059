<template>
  <AtomSectionHeader v-if="!isLoading" :title="title" />

  <div class="flex flex-col mx-auto pb-20 pt-4 px-4 max-w-screen-2xl sm:px-6 lg:pb-28 lg:pt-8 lg:px-8">
    <div v-if="isLoading">Loading…</div>
    <div v-else-if="hasRaceSeries && !isLoading">
      <div class="relative mx-auto">
        <div class="grid gap-8 mx-auto md:grid-cols-2 lg:max-w-none xl:grid-cols-3">
          <div
            v-for="series in raceSeries"
            :key="series.name"
            class="relative flex flex-col rounded-lg shadow-lg overflow-hidden"
          >
            <div class="flex-shrink-0">
              <RouterLink :to="{ path: `/series/${series.slug}` }" class="block">
                <img
                  class="w-full h-48 object-cover"
                  src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
                  alt=""
                />
              </RouterLink>
            </div>
            <div class="flex flex-1 flex-col justify-between mb-12 p-6 bg-white sm:mb-0">
              <div class="flex-1">
                <RouterLink :to="{ path: `/series/${series.slug}` }" class="block mt-2">
                  <p class="text-gray-900 text-xl font-semibold">
                    {{ series.name }}
                  </p>
                </RouterLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h3 v-else>No series found.</h3>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        title: 'Race Series',
        isLoading: null,
        error: null,
        raceSeries: null,
        jwt: null
      }
    },
    computed: {
      hasRaceSeries() {
        return !this.isLoading && this.raceSeries && this.raceSeries.length > 0
      },
      ...mapGetters({
        userJWT: 'getJWT',
        loggedInUser: 'auth/getLoggedInUser'
      })
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent() {
        this.isLoading = true
        await this.getRaceSeries()
        this.isLoading = false
      },
      async getRaceSeries() {
        this.isLoading = true

        try {
          this.raceSeries = await this.apiRequest(`/series`, null, null, {
            Authorization: this.userJWT
          })
        } catch (e) {
          if (e) console.log(`Error getting race series: ${e.status} ${e.data}`)
        }

        this.isLoading = false
      }
    }
  }
</script>
