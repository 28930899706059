<template>
  <Transition name="fade" appear>
    <OrganismCentered class="text-white bg-gray">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <AtomIconHeader :title="title" :classes="['text-black']" />
      </div>
    </OrganismCentered>
  </Transition>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import AtomIconHeader from '@/components/atoms/IconHeader'

  export default {
    components: { AtomIconHeader },
    props: {
      email: {
        type: String,
        default: null
      },
      username: {
        type: String,
        default: null
      },
      code: {
        type: String,
        default: null
      },
      region: {
        type: String,
        default: 'eu-west-1'
      },
      clientId: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        title: 'Verifying your account…'
      }
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent() {
        await this.verifyUser()
      },
      async verifyUser() {
        try {
          const data = {
            code: this.code,
            username: this.username,
            region: this.region,
            clientId: this.clientId,
            email: this.email
          }
          const res = await this.apiRequest('/user/verify', null, data, null, 'POST')

          this.$router.replace({
            path: '/sign-in',
            query: { verified: 'true', email: this.email }
          })
        } catch (e) {
          if (e.data.message.includes('combination not found')) {
            this.$store.dispatch('setDialog', {
              title: 'Error',
              content: 'The account you are trying to verify does not exist, please contact support.',
              ctaTitle: 'Contact us',
              error: true,
              action: '/contact',
              disableTimer: true
            })
          } else if (e.data.message.includes('confirmed')) {
            this.$store.dispatch('setDialog', {
              title: 'Account Verified',
              content: 'You have already verified this account.',
              ctaTitle: 'Sign in',
              confirmed: true,
              action: `/sign-in?email=${this.email}`,
              disableTimer: true
            })
          } else if (e.data.message.includes('exceeded')) {
            this.$store.dispatch('setDialog', {
              title: 'Error',
              content: 'You have tried to verify this account too many times. Please try again later.',
              error: true,
              disableTimer: true
            })
          } else if (e.data.message.includes('expired')) {
            try {
              await Auth.resendSignUp(this.email)
            } catch (e) {
              this.$store.dispatch('setDialog', {
                title: 'Sorry',
                content: `We've ran in to a problem, please contact support for help with your account`,
                ctaTitle: 'Contact us',
                error: true,
                action: '/contact',
                disableTimer: true
              })
            }

            this.$store.dispatch('setDialog', {
              title: 'Error',
              content:
                "Your verification code has expired so we've sent you another email with a new link to verify your account.",
              error: true,
              disableTimer: true
            })
          } else {
            this.$store.dispatch('setDialog', {
              title: 'Error',
              content: e.data.message,
              error: true,
              disableTimer: true
            })
          }
        }
      },
      async resendConfirmationCode() {
        try {
          await Auth.resendSignUp(this.email)
        } catch (e) {
          console.log('Error resending code:', e)
        }
      }
    }
  }
</script>
