<template>
  <div>
    <div class="text-center">
      <h2 class="text-gray-900 text-3xl font-extrabold tracking-tight leading-9 sm:text-4xl sm:leading-10">
        Subscribe
      </h2>
      <p class="text-gray-500 mt-4 text-lg leading-6">
        <StripeCheckout
          ref="checkoutRef"
          mode="subscription"
          :pk="publishableKey"
          :line-items="lineItems"
          :success-url="successURL"
          :cancel-url="cancelURL"
          @loading="(v) => (loading = v)"
        />
      </p>

      <div class="sm:col-span-2">
        <span class="inline-flex w-full rounded-sm shadow-sm">
          <button
            type="submit"
            :class="[
              'w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-sm text-white bg-blue-600 focus:outline-none focus:border-orange-500 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150',
              !isLoading ? 'hover:bg-blue-500 ' : 'bg-blue-700 cursor-default'
            ]"
            @click="submit"
          >
            <span v-if="!isLoading">Subscribe</span>
            <AtomLoader v-else></AtomLoader>
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import { StripeCheckout } from '@vue-stripe/vue-stripe'

  export default {
    components: {
      StripeCheckout
    },
    data() {
      this.publishableKey = process.env.VUE_APP_STRIPE_PUB

      return {
        isLoading: false,
        lineItems: [
          {
            price: 'price_1I84RwG9pxUjdGqDSNtzuAj9',
            quantity: 1
          }
        ],
        successURL: 'http://localhost:8080/order-complete',
        cancelURL: 'http://localhost:8080/order-failed'
      }
    },
    methods: {
      submit() {
        this.isLoading = true
        this.$refs.checkoutRef.redirectToCheckout()
      }
    }
  }
</script>
