<template>
  <MoleculeHero :title="title" :content="content"></MoleculeHero>

  <div class="mx-auto p-8 max-w-screen-2xl lg:py-12">
    <div v-if="isLoading">Loading…</div>
    <div v-else-if="hasNews && !isLoading" class="grid gap-5 mx-auto max-w-lg lg:grid-cols-3 lg:max-w-none">
      <AtomNewsItem
        v-for="item in news"
        :id="item.id"
        :key="item.id"
        :slug="item.slug"
        :type="item.type"
        :image="item.image"
        :title="item.title"
        :author="item.author"
        :content="item.content"
        :date="item.date"
        class="flex flex-col rounded-lg shadow-lg overflow-hidden"
      >
      </AtomNewsItem>
    </div>
    <h3 v-else>No news found.</h3>
  </div>
</template>

<script>
  import AtomNewsItem from '@/components/atoms/NewsItem'

  export default {
    components: { AtomNewsItem },
    data() {
      return {
        title: 'Latest News',
        content: 'Our latest news and announcements can be found below.',
        isLoading: false,
        error: null,
        news: null
      }
    },
    computed: {
      hasNews() {
        return !this.isLoading && this.news.length > 0
      }
    },
    created() {
      this.getNews()
    },
    methods: {
      async getNews() {
        this.isLoading = true

        try {
          this.news = await this.apiRequest(`/news`)
        } catch (e) {
          if (e) console.log(`Error getting news: ${e.status} ${e.data}`)
        }

        this.isLoading = false
      }
    }
  }
</script>
