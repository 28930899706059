<template>
  <Transition name="fade" appear>
    <OrganismCentered class="bg-gray">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <AtomIconHeader :title="form.title" :classes="['text-black']" />
      </div>

      <Transition name="slide-scale" appear>
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div class="p-6 bg-white rounded-lg">
            <MoleculeAuthForm :fields="form.fields" :cta="form.cta" :action="form.action"></MoleculeAuthForm>
          </div>
        </div>
      </Transition>
    </OrganismCentered>
  </Transition>
</template>

<script>
  import AtomIconHeader from '@/components/atoms/IconHeader'

  export default {
    components: { AtomIconHeader },
    props: {
      email: {
        type: String,
        default: null
      },
      code: {
        type: Number,
        default: null
      },
      username: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        iconFill: '#2c3e50'
      }
    },
    computed: {
      form() {
        if (this.email && this.code && this.username) {
          return {
            title: 'Set a new password',
            cta: 'Update password',
            action: 'auth/createNewPassword',
            fields: [
              {
                id: 'email',
                label: 'Email Address',
                elem: 'input',
                type: 'email',
                isRequired: 'true',
                value: this.email,
                disabled: true
              },
              {
                id: 'password',
                label: 'New Password',
                elem: 'input',
                type: 'password',
                isRequired: 'true',
                wide: true,
                combinedFields: true
              },
              {
                id: 'code',
                label: 'Code',
                elem: 'input',
                type: 'hidden',
                isRequired: 'true',
                value: this.code,
                disabled: true
              }
            ]
          }
        } else {
          return {
            title: 'Reset your password',
            cta: 'Reset password',
            action: 'auth/requestPasswordResetLink',
            fields: [
              {
                id: 'email',
                label: 'Email Address',
                elem: 'input',
                type: 'email',
                isRequired: 'true'
              }
            ]
          }
        }
      }
    }
  }
</script>
