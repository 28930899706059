<template v-if="!isLoading">
  <div>
    <AtomSectionHeader
      classes="md:px-4 lg:px-8"
      :title="title"
      icon="PlusIcon"
      :action-title="actionTitle"
      :admin-action="true"
      @action="buildForm"
    />
    <div class="mb-4 lg:mb-8">
      <OrganismOrganisers :organisers="organisers" />
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    inject: ['sidebar'],
    data() {
      return {
        title: 'Organisers',
        actionTitle: 'Add an organiser',
        content: 'You can view all of the organisers below.',
        error: null,
        form: {
          cta: 'Add organiser',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8',
          fields: [
            {
              id: 'name',
              label: 'Name',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'address_1',
              label: 'Address Line 1',
              elem: 'input',
              type: 'text',
              isRequired: 'true',
              classes: 'sm:col-span-2'
            },
            {
              id: 'address_2',
              label: 'Address Line 2',
              elem: 'input',
              type: 'text',
              isRequired: null,
              classes: 'sm:col-span-2'
            },
            {
              id: 'address_3',
              label: 'Address Line 3',
              elem: 'input',
              type: 'text',
              isRequired: null,
              classes: 'sm:col-span-2'
            },
            {
              id: 'town',
              label: 'Town',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'post_code',
              label: 'Postcode',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'email',
              label: 'Email Address',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            },
            {
              id: 'telephone',
              label: 'Phone Number',
              elem: 'input',
              type: 'text',
              isRequired: 'true'
            }
          ]
        },
        isSaving: false,
        jwt: null
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        panelStatus: 'getPanelStatus',
        isLoading: 'getLoadingStatus',
        loggedInUser: 'auth/getLoggedInUser',
        organisers: 'organisers/getOrganisers'
      }),
      isAdmin() {
        return this.loggedInUser && this.loggedInUser.admin
      }
    },
    mounted() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent() {
        this.$store.dispatch('setLoadingStatus', true)
        this.$store.dispatch('organisers/getOrganisers')
        this.$store.dispatch('setLoadingStatus', false)
      },
      async buildForm() {
        // Reset form data
        Object.assign(this.$data.form, this.$options.data().form)

        this.$store.dispatch('setPanel', {
          title: this.actionTitle,
          form: this.form,
          endpoint: '/organiser',
          errorMessage: "Sorry we couldn't add the organiser.",
          successMessage: 'Your organiser has been added.'
        })
      },
      async deleteOrganiser(organiserUid) {
        try {
          await this.apiRequest(`/organiser/${organiserUid}`, null, null, { Authorization: this.userJWT }, 'DELETE')

          this.$store.dispatch('setDialog', {
            title: 'Organiser Deleted!',
            content: '<p>The organiser has been deleted.</p>',
            ctaTitle: 'Close',
            confirmed: true
          })

          this.getOrganisers()
        } catch (e) {
          if (e) console.log(`Error deleting organiser: ${e.status} ${e.data}`)

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: "<p>Sorry we couldn't delete this organiser. Please try again.</p>",
            ctaTitle: 'Close',
            error: true
          })
        }
      }
    }
  }
</script>
