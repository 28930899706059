<template>
  <div class="bg-gray-50">
    <div class="mx-auto px-4 py-16 max-w-screen-2xl sm:px-6 lg:px-8 lg:py-24">
      <MoleculeFAQs :title="title" :content="content" :items="items"></MoleculeFAQs>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        title: 'Frequently Asked Questions',
        content:
          'Can’t find the answer you’re looking for? Send us a <a href="/contact" class="font-medium text-blue-600 hover:text-blue-500 transition duration-150 ease-in-out">message</a> or get in touch through your <a href="/account/support" class="font-medium text-blue-600 hover:text-blue-500 transition duration-150 ease-in-out">account</a>.',
        items: [
          {
            title: 'What do I need to bring with me?',
            content:
              'You need to bring your drivers license with you, which may be asked for at registration. If you do not have a road license but are over the age of 18, then a full competition license must be presented.'
          },
          {
            title: 'Do I need to wear a helmet?',
            content:
              'Yes, you need to wear a helmet at all times on track. If you are in an open car then the helmet needs to be full face, an open face helmet is okay in a closed car. The helmet needs to be from a recognised manufacturer.'
          },
          {
            title: 'What should I wear?',
            content:
              'You need to have your arms and legs covered at all times on circuit. A Nomex (or similar fire retardant material) race suit is advised however jeans and a long sleeve t-shirt are fine. You should wear shoes with a thin sole that you are comfortable driving in.'
          },
          {
            title: 'Do I need to have my car insured whilst on track?',
            content:
              'You don’t have to have your car insured on track, some conventional road insurance policies may even cover track use (check the small print).'
          },
          {
            title: 'Can I get photographs of my car out on track?',
            content:
              'We have professional photographers in attendance at all UK events. The photos will then be sent to you, free of charge after each event via email.'
          }
        ]
      }
    }
  }
</script>
