<template>
  <div v-if="!isLoading" class="bg-white overflow-hidden">
    <div class="relative mx-auto px-4 py-16 max-w-7xl sm:px-6 lg:px-8">
      <div class="bg-gray-50 absolute bottom-0 left-3/4 top-0 hidden w-screen lg:block"></div>
      <div class="mx-auto max-w-prose text-base lg:max-w-none">
        <p class="text-blue-600 text-base font-semibold tracking-wide leading-6 uppercase">
          {{ post.type }}
        </p>
        <h1 class="text-gray-900 mb-8 mt-2 text-3xl font-extrabold tracking-tight leading-8 sm:text-4xl sm:leading-10">
          {{ post.title }}
        </h1>
      </div>
      <div class="lg:grid lg:gap-8 lg:grid-cols-2">
        <div v-if="post.image" class="relative mb-8 lg:col-start-2 lg:row-start-1 lg:mb-0">
          <div class="relative mx-auto max-w-prose text-base lg:max-w-none">
            <figure>
              <div class="pb-7/12 relative lg:pb-0">
                <img
                  :src="post.image"
                  alt=""
                  class="inset-0 w-full h-full shadow-lg object-cover object-center lg:static lg:h-auto lg:rounded-lg"
                />
              </div>
              <figcaption class="text-gray-500 flex mt-3 text-sm">
                <BaseIcon><PhotographIcon /></BaseIcon>
                Photograph by Tom Sinclair
              </figcaption>
            </figure>
          </div>
        </div>
        <div>
          <div
            class="prose text-gray-500 mx-auto lg:col-start-1 lg:row-start-1 lg:max-w-none"
            v-html="post.content"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <!--<MoleculeHero v-else title="Loading…" />-->
</template>

<script>
  export default {
    data() {
      return {
        isLoading: false,
        error: null,
        post: null
      }
    },
    created() {
      this.getNewsPost()
    },
    methods: {
      async getNewsPost() {
        this.isLoading = true

        try {
          this.post = await this.apiRequest(`/news/${this.$route.params.slug}`)
        } catch (e) {
          if (e) console.log(`Error getting news post: ${e.status} ${e.data}`)
        }

        this.isLoading = false
      }
    }
  }
</script>
